.projects {
  font-family: "Anybody", cursive;
  padding-top: 100px;
}

.projects .container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
}

.projects h1 {
  color: #e29578;
  text-transform: uppercase;
  text-align: center;
  font-size: 45px;
}

.project-title {
  font-family: "Anybody", cursive;
  color: #3D5A80;
  font-weight: bold;
  text-transform: uppercase;
}

/* plant nursery project */
.plant-nursery-img {
  width: 500px;
  border-radius: 10px;
  /* margin: auto; */
}

.plant-nursery-project {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.plant-nursery-project p {
  font-family: "Nunito", sans-serif;
  /* padding: 30px; */
  font-size: 18px;
}

.programming-language {
  font-weight: bold;
}

/* doctors-visit project */
.doctor-visit-project {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 30px;
  /* border: 1px solid black;
  padding-bottom: 40px */
}

.doctor-visit-img {
  width: 500px;
  border-radius: 10px;
  /* margin-top: 50px; */
}

.doctor-visit-project p {
  font-family: "Nunito", sans-serif;
  /* padding: 30px; */
  font-size: 18px;
}

.project-info {
  padding: 30px;
}

/* dating app project */
.dating-app-project {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.dating-app-project p {
  font-family: "Nunito", sans-serif;
  /* padding: 30px; */
  font-size: 18px;
}

.dating-app-img {
  width: 280px;
  border-radius: 10px;
  margin-top: 10px;
}

/* resume project */
.resume-swype-project {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.resume-swype-img {
  width: 270px;
}

.resume-swype-project p {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
}

/* weights app */
.weights-img {
  width: 330px;
}

.weights-app {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.weights-app p {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
}

/* Float Shadow */
.hvr-float-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  margin-top: 10px;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(
    center,
    ellipse,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
.hvr-float-shadow:hover,
.hvr-float-shadow:focus,
.hvr-float-shadow:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  /* move the element up by 5px */
}
/* .hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before { */
.hvr-float-shadow:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

@media screen and (min-width: 961px) {
  .hide-title-desktop {
    display: none;
  }

  .hide-desktop {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .doctor-visit-project,
  .plant-nursery-project,
  .dating-app-project,
  .resume-swype-project {
    display: flex;
    flex-direction: column;
  }

  .doctor-visit-img,
  .plant-nursery-img {
    width: 350px;
    border-radius: 20px;
  }

  .doctor-visit-project p,
  .plant-nursery-project p,
  .dating-app-project p,
  .resume-swype-project p {
    font-size: 15px;
  }

  .doctor-visit-project,
  .resume-swype-project {
    margin-bottom: 0px;
  }

  .doctor-visit-project p,
  .plant-nursery-project p,
  .dating-app-project p,
  .resume-swype-preject p {
    padding-bottom: 0px;
  }

  .plant-nursery-project {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .dating-app-img {
    width: 180px;
    border-radius: 20px;
  }

  .dating-app-project {
    margin-top: 20px;
  }

  .project-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-title {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .hide-mobile {
    display: none;
  }

  .hide-title-mobile {
    display: none;
  }
}