.icons {
  width: 100px;
  margin: auto;
  padding: 10px;
}

.skills .icons-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 15px;
}

.skills-header {
  text-transform: uppercase;
  color: #E29578;
  font-family: 'Anybody', cursive;
  /* font-family: 'Karla', sans-serif; */
  text-align: center;
  font-size: 45px;
}

.skills-name {
  color: #778DA9

}

.skills {
  /* background-color: #F3EEC3; */
  padding: 15px;
}

.skills .container {
  /* margin-top: 60px; */
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* Float Shadow */
.hvr-float-shadow-skills {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-float-shadow-skills:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
.hvr-float-shadow-skills:hover, .hvr-float-shadow-skills:focus, .hvr-float-shadow-skills:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  /* move the element up by 5px */
}
.hvr-float-shadow-skills:hover:before, .hvr-float-shadow-skills:focus:before, .hvr-float-shadow-skills:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

.hvr-float-shadow-skills {
  text-align: center;
}

@media screen and (max-width: 960px) {
  .icons {
    width: 70px;
  }

  .skills {
    padding: 0;
  }
  
  .skills .icons-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .icons {
    padding: 5px;
  }

  .skills .container {
    margin-left: 0;
    margin-right: 0;
  }

  .skills-name {
    font-size: 1em;
  }

  .skills-header {
    font-size: 35px;
  }
}