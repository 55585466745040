.img {
  width: 250px;
  border-radius: 50px;
}

.about-me .container {
  margin-top: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* flex-wrap: wrap; */
}

.about-me .container .content {
  padding: 50px;
}

.title-about-me {
  color: #E29578;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Anybody', cursive;
}

@media screen and (max-width: 960px) {
  .about-me .container {
    flex-direction: column;
  }

  .img {
    width: 150px;
    border-radius: 20px;
  }

  .about-me .container .content {
    padding: 30px;
  }
  
}