.header {
  font-family: "Anybody", cursive;
}

.social-icons {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.social-icons img {
  padding: 0 15px;
}

.resume,
.contact {
  padding: 0 15px;
  color: black;
}

span {
  color: #e29578;
}

.header h2 {
  text-transform: uppercase;
}

.header-navigation {
  background: #fae1dd;
  color: black;
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  cursor: pointer;
  z-index: 1;
}

.header-navigation .header-links a {
  padding: 20px 50px;
}

.desktop-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* hamburger menu for mobile */
/* https://alvarotrigo.com/blog/hamburger-menu-css/ */
.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: black;
  height: 30px;
  padding: 1em;
}

.menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  margin-top: 60px;
  right: 0;
  flex-direction: column;
  width: 170px;
  justify-content: center;
  align-items: center;
  background-color: #aed9e0;
  opacity: 0.85;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: flex;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #000;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

#menu-toggle ~ .menu > li {
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

#menu-toggle:checked ~ .menu > li {
  height: 2.5em;
  padding-top: 20px;
  transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 3;
}

.menu > li {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0.5em 0;
  width: 100%;
  color: black;
}

.menu > li:hover,
.li-social-icons .social-icons li:hover {
  background-color: #e0e2ce;
}

.menu span {
  /* color: #1b263b; */
  color: black;
  text-decoration: none;
}

.social-icon {
  width: 40px;
}
/* mobile social icons */
/* css specificity requires this */
#menu-toggle:checked ~ .menu .li-social-icons {
  height: 240px;
  padding-top: 0px;
}

.li-social-icons ul {
  list-style: none;
  padding-inline-start: 0px;
}

.mobile-nav .social-icons {
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin-left: 0px;
}

.mobile-nav .social-icons li,
.mobile-nav .social-icons a {
  width: 100%;
}

#socials {
  margin-top: 30px;
}

#socials a {
  text-decoration: none;
}

/* .header-navigation a:hover {
  color: #6C757D;
} */

.header .container {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header h1 {
  font-size: 60px;
}

.header h3 {
  font-size: 25px;
}

.blinking {
  animation: blinkingText 1.5s infinite;
}

@keyframes blinkingText {
  0% {
    color: #e29578;
  }
  49% {
    color: #83c5be;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #e29578;
  }
}

.fade-in-text {
  font-size: 150px;
  animation: fadeIn 2.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-text-2 {
  /* font-size: 150px; */
  animation: fadeIn2 3.5s;
  margin-top: 50px;
  margin-bottom: 50px;
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* .loader {
  display: flex;
  align-self: center;
  margin-top: 50px;
  the colors
  --c1: #f5cac3;
  --c2: #6c757d;
  --c3: #d5bdaf;
  --c4: #1d3557;
  

  width: 150px;
  aspect-ratio: 5/3;
  --_g: no-repeat radial-gradient(#000 68%, #0000 71%);
  -webkit-mask: var(--_g), var(--_g), var(--_g);
  -webkit-mask-size: 25% 40%;
  background: conic-gradient(var(--c1) 50%, var(--c2) 0) no-repeat,
    conic-gradient(var(--c3) 50%, var(--c4) 0) no-repeat;
  background-size: 200% 50%;
  animation: back 4s infinite steps(1), load 2s infinite;
} */

/* @keyframes load {
  0% {
    -webkit-mask-position: 0% 0%, 50% 0%, 100% 0%;
  }
  16.67% {
    -webkit-mask-position: 0% 100%, 50% 0%, 100% 0%;
  }
  33.33% {
    -webkit-mask-position: 0% 100%, 50% 100%, 100% 0%;
  }
  50% {
    -webkit-mask-position: 0% 100%, 50% 100%, 100% 100%;
  }
  66.67% {
    -webkit-mask-position: 0% 0%, 50% 100%, 100% 100%;
  }
  83.33% {
    -webkit-mask-position: 0% 0%, 50% 0%, 100% 100%;
  }
  100% {
    -webkit-mask-position: 0% 0%, 50% 0%, 100% 0%;
  }
}
@keyframes back {
  0%,
  100% {
    background-position: 0% 0%, 0% 100%;
  }
  25% {
    background-position: 100% 0%, 0% 100%;
  }
  50% {
    background-position: 100% 0%, 100% 100%;
  }
  75% {
    background-position: 0% 0%, 100% 100%;
  }
} */

/* text carousel */
.header-carousel {
  position: relative;
  color: black;
  overflow: hidden;
  height: 24px;
}

.element {
  margin-top: 0;
  font-size: 24px;
  line-height: 24px;
  /* add color */
}

.text-carousel {
  animation: carousel 5s ease-in-out infinite;
}

@keyframes carousel {
  0%,
  20% {
    transform: translateY(0);
  }
  25%,
  45% {
    transform: translateY(-48px);
  }
  50%,
  70% {
    transform: translateY(-96px);
  }
  75%,
  95% {
    transform: translateY(-144px);
  }
  100% {
    transform: translateY(-192px);
  }
}

@keyframes carouselMobile {
  0%,
  20% {
    transform: translateY(0);
  }
  25%,
  45% {
    transform: translateY(-44px);
  }
  50%,
  70% {
    transform: translateY(-88px);
  }
  75%,
  95% {
    transform: translateY(-132px);
  }
  100% {
    transform: translateY(-176px);
  }
}
.carousel-container {
  display: flex;
  align-items: center;
}

.carousel-container span {
  font-size: 22px;
  color: black;
}

p.element {
  font-weight: bold;
  font-size: 24px;
  color: #e29578;
}

/* Underline Reveal */
.hvr-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #84a59d;
  height: 4px;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-reveal:hover:before,
.hvr-underline-reveal:focus:before,
.hvr-underline-reveal:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@media screen and (max-width: 960px) {
  .header-navigation .header-links a {
    padding: 20px 10px;
    font-size: 14px;
  }

  .desktop-nav {
    display: none;
  }

  /* .desktop-nav .social-icons {
    display: none;
  } */

  .desktop-nav .social-icons img {
    padding: 0 10px;
    width: 20px;
  }

  .mobile-nav .social-icons img {
    padding: 8px;
  }

  .contact-icon,
  .resume-icon {
    padding: 8px;
  }

  .header .container {
    margin-top: 0px;
    height: 100vh;
  }

  .projects,
  .about-me {
    padding-top: 70px;
  }

  .carousel-container {
    display: block;
  }

  .carousel-container span {
    font-size: 16px;
  }

  .header-carousel {
    margin-top: 10px;
  }

  .text-carousel {
    animation: carouselMobile 5s ease-in-out infinite;
  }

  p.element {
    font-size: 20px;
  }

  /* .loader {
    width: 100px;
  } */

  .header h1 {
    font-size: 45px;
  }

  .header h2 {
    font-size: 1.1em;
  }

  /* disable hover effect for mobile */
  .hvr-underline-reveal:before {
    display: none;
  }
}

@media screen and (min-width: 961px) {
  .mobile-nav {
    display: none;
  }
}
