body {
  margin-top: 0;
  font-family: 'Nunito', sans-serif;
  /* background-color: #F3EEC3 */
}

.container {
  max-width: 960px;
  margin: auto;
}

@media screen and (max-width: 960px) {
  .container {
    max-width: 768px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
}